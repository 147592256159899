<div class="payment-component payment-delivery-component" *ngIf="_isDeliveryZone !== null">
  <div class="row">
    <div class="col">
      <span class="payment-delivery-title">
        {{ 'tapp-order.payment.select-delivery-type.title' | translate }}
      </span>
    </div>
  </div>
  <div class="row payment-delivery-wrapper">
    <ng-container *ngIf="deliveryTypes.length">
      <ng-container *ngFor="let delivery of deliveryTypes">
        <div class="col-md-6 p-field-radiobutton">
          <label for="{{ delivery.value }}" class="delivery-type-card">
            <p-radioButton
              [value]="delivery"
              [(ngModel)]="selectedDeliveryType"
              [inputId]="delivery.value"
              [ngClass]="'radio'"
              (ngModelChange)="changeDeliveryType()"
            ></p-radioButton>
            <span [class.active]="selectedDeliveryType == delivery" class="label">{{ delivery.name | translate }}</span>
          </label>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-12">
      <span
        *ngIf="selectedDeliveryType.value === deliveryTypeEnum.delivery; else pickupTitle"
        class="payment-delivery-title"
        >{{ 'tapp-order.payment.select-delivery-time.dilivery-time' | translate }}</span
      >
      <ng-template #pickupTitle>
        <span class="payment-delivery-title">{{
          'tapp-order.payment.select-delivery-time.pickup-time' | translate
        }}</span>
      </ng-template>
    </div>
    <div class="col-md-6 p-field-radiobutton" (click)="setSelectedDeliveryHoursType(this.asapDeliveryHours)">
      <div class="delivery-type-card">
        <p-radioButton
          [value]="this.asapDeliveryHours"
          [(ngModel)]="selectedDeliveryHoursType"
          [inputId]="'deliveryHoursType1'"
          [ngClass]="'radio'"
          (change)="changeDeliveryHoursType($event)"
        ></p-radioButton>
        <span [class.active]="selectedDeliveryHoursType == this.asapDeliveryHours" class="label">{{
          'tapp-order.payment.select-delivery-time.asap' | translate
        }}</span>
      </div>
    </div>

    <div
      *ngIf="deliveryHours.length"
      class="col-md-6 p-field-radiobutton"
      (click)="setSelectedDeliveryHoursType(this.specifyDeliveryHours)"
    >
      <div class="delivery-type-card">
        <p-radioButton
          #deliveryHoursType2
          [value]="this.specifyDeliveryHours"
          [(ngModel)]="selectedDeliveryHoursType"
          [inputId]="'deliveryHoursType2'"
          [ngClass]="'radio'"
          (change)="changeDeliveryHoursType($event)"
        ></p-radioButton>
        <!--<img class="icon-timing" src="../../../../assets/icons/timing.png" />-->
        <ng-container *ngIf="_isDeliveryZone; else pickupLabel">
          <span
            [class.active]="selectedDeliveryHoursType == this.specifyDeliveryHours"
            class="delivery-hour-text label"
            >{{ 'tapp-order.payment.select-delivery-type.delivery-hour' | translate }}</span
          >
        </ng-container>
        <ng-template #pickupLabel>
          <span
            [class.active]="selectedDeliveryHoursType == this.specifyDeliveryHours"
            class="delivery-hour-text label"
            >{{ 'tapp-order.payment.select-delivery-time.pickup-time' | translate }}</span
          >
        </ng-template>

        <div class="col d-flex justify-content-end">
          <p-dropdown
            [options]="deliveryHours"
            optionLabel="label"
            (onChange)="changeDeliveryHours($event)"
            [virtualScroll]="false"
            dropdownIcon="ic ic-arrow-down"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="displayDialog"
  [draggable]="false"
  [modal]="true"
  [showHeader]="false"
  [styleClass]="'change-delivery-dialog'"
  [transitionOptions]="'0ms'"
>
  <div class="row" class="dialog-change-delivery-container">
    <p class="change-delivery-dialog-header-text">
      {{ 'tapp-order.pages.discount.dialog.delivery-change-title' | translate }}
    </p>

    <div class="buttons-container">
      <button class="save-button ui-clickable" (click)="close()">
        {{ 'tapp-order.pages.discount.dialog.buttons.close' | translate }}
      </button>
    </div>
  </div>
</p-dialog>
