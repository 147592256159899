import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { NewOrderService } from '@core/http/tapp-order/order/new-order.service';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { NewOrderApiModel } from '@core/models/tapp-order/api-model/order/new-order.api.model';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { DiscountService } from '@core/pages/discounts/service/discount.service';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';
import { OrderService } from '@core/services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { AbandonedBasketService } from '@ui/abandoned-basket/abandoned-basket.service';
import { ConfirmationService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { PlaceService } from '../../services/place.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnChanges, OnDestroy {
  private _basketItems: BasketItemViewModel[] = [];
  public displayDialog: boolean = false;
  public deliveryZone: DeliveryZoneApiModel;
  public placeString: string;
  public place: PlaceViewModel;
  public discountNames: string[][] = [];
  private _orderType: string;
  deliveryOrderType = OrderTypeEnum.delivery;
  public promoCode: string = '';

  @ViewChild('sidebarList') sidebarList;

  @Input() public basketItemsAmount: number;
  @Input() public basketItemsPrice: number;
  @Input() public productsCrossSell: number = 0;
  @Input() public paymentMode: boolean = false;
  @Input() public isOrderButtonActive: boolean = true;
  @Input() public isSummaryMode: boolean = false;
  @Input() public sessionStorageNewOrder: any = null;
  @Input() public isTermsAndConditionsAccepted: any = false;
  @Input() public isOpen: boolean = true;
  @Input() public nextWorkingHours: WorkingHoursApiModel;

  @Output() public termsAndConditionsNotAcceptedEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() public openCrossSellDialogEvent: EventEmitter<null> = new EventEmitter<null>();

  @Input()
  public set basketItems(value: BasketItemViewModel[]) {
    this._basketItems = value;
  }

  public get basketItems(): BasketItemViewModel[] {
    return this._basketItems;
  }

  public displayPosStatusDialog: boolean = false;
  public displayPosStatusDialogPlace: PlaceViewModel;
  private isOrderSendPending = false;

  private destroyed$ = new Subject<void>();
  private discountSubscription$?: Subscription;
  public checkPromotions: boolean = false;

  constructor(
    public basketService: BasketService,
    public discountService: DiscountService,
    public orderService: OrderService,
    private router: Router,
    private translateService: TranslateService,
    private placeDataProvider: PlaceDataProvider,
    private confirmationService: ConfirmationService,
    private newOrderService: NewOrderService,
    private deliveryZoneService: DeliveryZonesHttpService,
    private placeService: PlaceService,
    private paymentFormService: PaymentFormService,
    private baseDataProvider: BaseDataProvider,
    private abandonedBasketService: AbandonedBasketService,
    private route: ActivatedRoute,
  ) {
    if (this.orderService.tableId) {
      this.orderService.setOrderType(OrderTypeEnum.at_location);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.discountService.calculate();
    if (!this.paymentMode && !this.isSummaryMode) {
      this.discountService.isBasketIsCalculating.pipe(first(), takeUntil(this.destroyed$)).subscribe((value) => {
        if (!value) {
          this.sidebarList.nativeElement.scrollTop = this.sidebarList.nativeElement.scrollHeight;
        }
      });
    }
  }

  ngOnInit(): void {
    this.setDiscountNames();
    this.promoCode = this.discountService.promoCode;

    this.route.params.subscribe((params) => {
      this.placeService.getPlace().subscribe((place: PlaceViewModel) => {
        this.place = place;
        this.placeString = `${place.city}, ${place.street} ${place.buildingNo}`;
        place.localNo ? (this.placeString += `/${place.localNo}`) : null;
        this.checkPromotions = place.getMenuByType(this.orderService.getMenuType())?.checkPromotions;

        if (!this.orderService.tableId) {
          this.deliveryZoneService.getDeliveryZones(sessionStorage.getItem('placeId')).subscribe((deliveryZones) => {
            if (deliveryZones.length) {
              this.setDeliveryZone();
              this.orderService.deliveryZoneSubject$.subscribe((res: DeliveryZoneApiModel) => {
                if (res !== null) {
                  this.deliveryZone = res;
                }
              });

              this._orderType = this.orderService.getOrderType();
              this.orderService.orderTypeSubject$.subscribe((res: string) => {
                if (res !== null) {
                  this._orderType = res;
                }
              });
            }
          });
        } else {
          this.deliveryZone = null;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.discountSubscription$?.unsubscribe();
  }

  public activateDiscountCode() {
    this.discountService.calculate(this.promoCode);
  }

  public hanldePromoCodeChange(promoCode: string): void {
    this.promoCode = promoCode;

    if (!promoCode && this.discountService.promoCode) {
      this.discountService.calculate('');
    }
  }

  public createOrder() {
    if (this.basketService.basketItems.length === 0) {
      return;
    } else if (this.paymentMode === false) {
      this.router.navigate(['/payments']);
    } else if (this.isOrderButtonActive) {
      this.placeDataProvider
        .getCurrentOpeningStatus()
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe((isOpen) => {
          if (!isOpen) {
            this.placeDataProvider
              .getNextWorkingHours()
              .pipe(first(), takeUntil(this.destroyed$))
              .subscribe((nextWorkingHours) => {
                this.showClosedPlaceDialog(nextWorkingHours);
              });
            return;
          }

          this.paymentFormService.isNewOrderCanBeCreated().then((value) => {
            if (value && this.isOrderButtonActive) {
              this.isOrderButtonActive = false;
              globalCacheBusterNotifier.next();
              this.displayPosStatusDialog = false;
              this.displayPosStatusDialogPlace = null;

              this.baseDataProvider
                .getPlace(sessionStorage.getItem('placeId'))
                .pipe(first(), takeUntil(this.destroyed$))
                .subscribe(
                  (placeViewModel: PlaceViewModel) => {
                    if (placeViewModel.posStatusCheck === true) {
                      if (placeViewModel.posStatus == 'PAUSED') {
                        this.displayPosStatusDialog = true;
                        this.displayPosStatusDialogPlace = placeViewModel;
                        this.isOrderButtonActive = true;
                      } else {
                        this.createNewOrder();
                      }
                    } else {
                      this.createNewOrder();
                    }
                  },
                  () => {
                    this.isOrderButtonActive = true;
                  },
                );
            }
          });
        });
    }
  }

  checkCreateOrder() {
    if (this.productsCrossSell > 0) {
      this.openCrossSellDialogEvent.emit();
    } else {
      this.createOrder();
    }
  }

  closePosStatusDialog() {
    this.displayPosStatusDialog = false;
    this.displayPosStatusDialogPlace = null;
  }

  private setDeliveryZone(): void {
    if (this.orderService.getDeliveryZoneId()) {
      this.deliveryZoneService
        .getDeliveryZoneById(this.getLocalPublicId(), this.orderService.getDeliveryZoneId().toString())
        .subscribe(
          (result) => {
            this.orderService.setDeliveryZone(result);
            this.deliveryZone = result;
          },
          () => {
            this.orderService.removeDeliveryZoneId();

            this.orderService.removeDeliveryAddressCity();
            this.orderService.removeDeliveryAddressZipCode();
            this.orderService.removeDeliveryAddressStreet();
            this.orderService.removeDeliveryAddressBuildingNo();
            this.orderService.removeDeliveryAddressLocalNo();
          },
        );
    }
  }

  public createNewOrder() {
    this.abandonedBasketService.disableTimer();

    if (!this.isOrderSendPending) {
      this.isOrderSendPending = true;

      this.newOrderService
        .create(this.basketService.basketItems)
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe(
          (newOrderCreateResponse) => {
            this.isOrderSendPending = false;
            try {
              sessionStorage.setItem('NewOrderApiModel', JSON.stringify(newOrderCreateResponse));
            } catch (error) {
              console.error('Error saving to sessionStorage', error);
            }

            window.location.href = newOrderCreateResponse.orderPayment.providerPaymentUrl;
          },
          (error: HttpErrorResponse) => {
            this.isOrderSendPending = false;
            this.isOrderButtonActive = true;

            switch (error.error?.code) {
              case 'order.order.max_product_quantity_limit':
                // Exceeded the maximum quantity of some products in the order
                this.confirmationService.confirm({
                  header: TitleCasePipe.prototype.transform(this.translateService.instant('common.word.failure')),
                  message: this.extractMessageFromApiResponse(error.error),
                  acceptLabel: 'Ok',
                  accept: () => {},
                  reject: () => {},
                  key: 'confirm-dialog-live-order',
                });
                break;
              default:
                this.router.navigate(['error', '20000']);
                break;
            }
          },
        );
    }
  }

  private extractMessageFromApiResponse(response: any): string {
    let message = response.message;
    for (const detail in response.details) {
      const value = response.details[detail];
      message = message.replace(`{{ ${detail} }}`, value);
    }
    return message;
  }

  public calculateMinOrderPrice() {
    if (this.deliveryZone) {
      return this.deliveryZone.minOrderPrice - this.basketService.getFoodTotalPrice();
    } else {
      //jeśli nie ma strefy dostawy, zawsze zezwalaj kupowac bez warunku - w końcu sam odbierzesz!
      return -1;
    }
  }

  public calculateDeliveryCost() {
    if (this.orderService.getOrderType() !== this._orderType || this._orderType !== OrderTypeEnum.delivery) {
      return 0;
    }

    if (!this.deliveryZone) {
      return 0;
    }

    return this.deliveryZone.deliveryPrice;
  }

  public displayConfirmDialog = false;

  public checkDeliveryFreeCost() {
    if (this.paymentMode === false) {
      if (this.deliveryZone.minOrderPriceForFreeDelivery) {
        if (this.deliveryZone.minOrderPriceForFreeDelivery >= this.basketItemsPrice) {
          if (this.checkIsShowConfirmDialog()) {
            this.displayConfirmDialog = true;
          } else {
            this.createOrder();
          }
        } else {
          this.createOrder();
        }
      } else {
        this.createOrder();
      }
    } else {
      this.createOrder();
    }
  }

  public checkIsShowConfirmDialog() {
    let percet = 0.8;
    let value = 50;

    let percentFreeDeliveryCost = this.deliveryZone.minOrderPriceForFreeDelivery * percet;

    let valueFreeDeliveryCost = this.deliveryZone.minOrderPriceForFreeDelivery - this.basketItemsPrice;

    if (this.basketItemsPrice >= percentFreeDeliveryCost) {
      if (valueFreeDeliveryCost <= value) {
        return true;
      }
    }
    return false;
  }

  public calculateFreeDeliveryCost() {
    if (this.deliveryZone) {
      return this.deliveryZone.minOrderPriceForFreeDelivery - this.basketService.getFoodTotalPrice();
    }
  }

  public getLocalPublicId() {
    return sessionStorage.getItem('placeId');
  }

  public changeLocal() {
    this.displayDialog = true;
  }

  public closeDialog() {
    this.displayDialog = false;
  }

  public closeConfirmDialog() {
    this.displayConfirmDialog = false;
  }

  public openDiscountDialog() {
    this.basketService.refreshBasketEvent.subscribe(() => {
      console.log('add disocunt ---');
    });
  }

  private setDiscountNames(): void {
    const order: NewOrderApiModel | undefined = JSON.parse(sessionStorage.getItem('NewOrderApiModel'));

    if (order) {
      this.discountNames = order?.orderItems.map((item) => {
        return item.discounts.map((discount) => {
          return discount.name;
        });
      });
    } else {
      this.discountNames = [];
    }
  }

  private showClosedPlaceDialog(nextWorkingHours: WorkingHoursApiModel): void {
    this.confirmationService.confirm({
      header: TitleCasePipe.prototype.transform(this.translateService.instant('common.word.failure')),
      message: this.getClosedPlaceMessage(nextWorkingHours),
      acceptLabel: 'Ok',
      accept: () => {
        this.router.navigate(['/', sessionStorage.getItem('placeId')]);
      },
      reject: () => {
        this.router.navigate(['/', sessionStorage.getItem('placeId')]);
      },
      key: 'confirm-dialog-live-order',
    });
  }

  private getClosedPlaceMessage(nextWorkingHours: WorkingHoursApiModel): string {
    const day = this.translateService.instant(`app.weekDays.${nextWorkingHours.weekDay}`);
    const time = nextWorkingHours.startTime;

    return this.translateService.instant('app.pages.payment.closedPlaceMessage', { day, time });
  }
}
